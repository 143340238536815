.reports-container {
    padding: 20px;
  }
  
  .back-button {
    margin-bottom: 20px;
  }
  
  .center-logo {
    justify-content: center;
    align-items: center;
    height: 300px; /* Adjust based on your header/footer height */
    text-align:center;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .tile-button {
    padding: 20px;
    background-color: #f3f4f6;
    border: none;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .tile-button:hover {
    background-color: #e5e7eb;
  }
  
  .sub-section-content {
    margin-top: 20px;
  }
  