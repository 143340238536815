.sidebar {
    height: calc(100vh - 4rem); /* Adjust based on your header/footer height */
    overflow-y: scroll;
    padding-top: 30px;
  }
  
  .sidebar::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just to hide the scrollbar */
  }
  
  .sidebar ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .sidebar ul ul {
    padding-left: 20px;
  }
  
  .sidebar ul ul li {
    margin-top: 5px;
  }
  
  .bg-gray-400 {
    background-color: #d1d5db; /* Tailwind's gray-400 color */
  }
  
  .text-gray-700 {
    color: #374151; /* Tailwind's gray-700 color */
  }
  
  .text-white {
    color: #ffffff; /* Tailwind's white color */
  }
  
  .hover\:bg-gray-100:hover {
    background-color: #f3f4f6; /* Tailwind's gray-100 color */
  }
  
  .hover\:text-gray-700:hover {
    color: #374151; /* Tailwind's gray-700 color */
  }
  
  /* Additional CSS for multi-line hover effect */
  .nav-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
  }
  
  .nav-item:hover {
    background-color: #f3f4f6; /* Tailwind's gray-100 color */
    color: #374151; /* Tailwind's gray-700 color */
  }
  
  .nav-item h2 {
    white-space: normal;
    word-break: break-word;
  }
  